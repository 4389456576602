/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from 'ttag';
import { Switch, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styled, { css } from 'react-emotion';
import { Reference } from '../../helper/utils';
import callApi from '../../helper/api-fetch';
import PriceLabel from './PriceLabel';

const styles = theme => ({
  uppercaseTooltip: {
    textTransform: 'uppercase',
    fontSize: 13,
    backgroundColor: theme.palette.grey[700],
    position: 'relative',
    top: '10px',
  },
});

const FlexRow = styled('div')`
    display: flex; 
    flex-direction: row;
    margin-top:10px;
`;
const LabelLastCrawl = styled('div')`
    color: #cecece; 
    font-size: 11px;
    font-style: italic;
    margin-left:30px
`;

const Brand  = styled('div')`
    font-weight: bold;
    text-transform: uppercase;
    border: 1px #90c9ff solid;
    background-color: #90c9ff;
    color: #FFF;
    border-radius: 3px;
    padding: 0px 3px;
    margin-top: 4px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 11px;
    cursor: pointer;
`;

class ProductDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMatched: props.isMatched,
      handtoggleMatching : false
    };

    this.toggleMatching = this.toggleMatching.bind(this);
  }

  callMatchingApi() {
    const { isMatched } = this.state;
    const {
      productId, sourceProductId, onMatchCallEnded, notifHandler,
    } = this.props;

    callApi({
      path: `/products/${sourceProductId}/matching/${isMatched ? 'create' : 'delete'}/${productId}`,
      method: 'PUT',
      errorMessages: {
        404: t`Le produit sélectionné n'existe plus en base de données`,
      },
      onSuccess: (data) => {
        onMatchCallEnded(data.matchedProductIds);
        notifHandler(`Matching ${isMatched ? t`ajouté` : t`supprimé`}`);
      },
      onError: (error) => {
        this.setState(prevState => ({ isMatched: !prevState.isMatched }));
        notifHandler(error.message);
      },
    });
  }

  toggleMatching() {
    this.setState(
      prevState => ({
        isMatched: !prevState.isMatched,
        handtoggleMatching : true
      }),
      () => {
        this.callMatchingApi();
      },
    );
  }

  render() {
    const {
      domain, allReferences, productId, classes, websiteState, httpCode, dateLastCrawl, notifHandler, isShowPrice, 
      tauxPrice , vatSite , competitorsVat
    } = this.props;
    const {
      prix_prod, nom_prod, url_prod, nom_marque, id_si
    } = this.props.product

    const price = prix_prod ? (tauxPrice == 1 ? prix_prod : (parseFloat(prix_prod) / (1 + competitorsVat[id_si])).toFixed(2) ) : prix_prod;
    const { isMatched, reloadProduct } = this.state;

    return (
      !reloadProduct && 
      (<Row data-product_id={productId}>
        <div>
          <Reference value={allReferences} />
          {nom_marque && (<Brand dangerouslySetInnerHTML={{ __html: nom_marque }} />)}
          <FlexRow>
            <Domain>
              {domain}
            </Domain>
            <LabelLastCrawl>
              {dateLastCrawl ? `Update : ${dateLastCrawl.toISOString().slice(0, 10)} à ${dateLastCrawl.getHours()}:${dateLastCrawl.getMinutes()}` : null}
            </LabelLastCrawl>
            
          </FlexRow>
        </div>
        <div className={css({ textAlign: 'right' })}>
          <Tooltip title={t`Associer ce produit`} placement="top" classes={{ tooltip: classes.uppercaseTooltip }}>
            <Switch checked={isMatched} onChange={this.toggleMatching} color="primary" />
          </Tooltip>
          <div style={{ textAlign: 'center', marginTop: '4px' }}>
            <PriceLabel
              dateLastCrawl={dateLastCrawl}
              httpCode={httpCode}
              websiteState={websiteState}
              price={parseFloat(price)}
              productId={productId}
              isShowPrice={isShowPrice}
              handtoggleMatching = {true}
              isMatched = {isMatched}
            />
          </div>
        </div>
      </Row>)
    );
  }
}

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
`;
const Domain = styled('div')`
  color: #097212;
  margin: -3px 0 0 3px;
`;

ProductDescription.defaultProps = {
  isMatched: false,
  allReferences: [],
  onMatchCallEnded: () => {},
  notifHandler: () => {},
};

ProductDescription.propTypes = {
  productId: PropTypes.number.isRequired,
  sourceProductId: PropTypes.number.isRequired,
  isMatched: PropTypes.bool,
  onMatchCallEnded: PropTypes.func,
  notifHandler: PropTypes.func,
  domain: PropTypes.string.isRequired,
  allReferences: PropTypes.arrayOf(PropTypes.any),
  price: PropTypes.number.isRequired,
  websiteState: PropTypes.string.isRequired,
  httpCode: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  isShowPrice : PropTypes.bool.isRequired,
  vatSite: PropTypes.bool.isRequired,
  tauxPrice: PropTypes.bool.isRequired,
  competitorsVat: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ProductDescription);
