/* eslint-disable */
import React from 'react';
import Description from './ProductDescription';
import {
  getImagePath, getDomainFromUrl, makeSpanLink, cleanElasticResult,
} from '../../helper/utils';

const generateResultItem = (
  productId,
  elasticResult,
  matchedProductIds,
  notifHandler,
  handleMatchCallEnded,
  websiteStates,
  vatSite, 
  tauxPrice, 
  competitorsVat
) => {
  const {
    nom_prod, url_prod, reference, ref_fabriquant, http_code,
    ref_fournisseur, ean, id_si, image_nom_prod, date_last_crawl,
  } = elasticResult;
  const websiteState = websiteStates.hasOwnProperty(id_si) ? websiteStates[id_si].state : 'OK';

  const { id_prod } = cleanElasticResult(elasticResult);

  const domain = getDomainFromUrl(url_prod);
  const isMatched = matchedProductIds.includes(id_prod);

  return {
    image: getImagePath(id_si, image_nom_prod),
    image_size: 'small',
    title: makeSpanLink(nom_prod, url_prod),
    description: (
      <Description
        className="product-card"
        sourceProductId={productId}
        domain={domain}
        websiteState={websiteState}
        httpCode={http_code}
        productId={id_prod}
        allReferences={[reference, ref_fournisseur, ref_fabriquant, ean]}
        dateLastCrawl={date_last_crawl ? new Date(date_last_crawl) : null}
        isMatched={isMatched}
        notifHandler={notifHandler}
        onMatchCallEnded={handleMatchCallEnded}
        product={elasticResult}
        isShowPrice={true}
        vatSite={vatSite}
        tauxPrice={tauxPrice}
        competitorsVat={competitorsVat}
      />
    ),
  };
};

export default generateResultItem;
