/* eslint-disable */
import React from 'react';
import Description from './ProductDescription';
import {
  getImagePath, getDomainFromUrl, makeSpanLink, cleanElasticResult,
} from '../../helper/utils';

const generateResultItemCompetitor = (
  productId,
  elasticResult,
  matchedProductIds,
  notifHandler,
  handleMatchCallEnded,
  websiteStates,
  vatSite, 
  tauxPrice, 
  competitorsVat
) => {
  const {
    nom_prod, url_prod, reference, ref_fabriquant, http_code,
    ref_fournisseur, ean, id_si, image_nom_prod, date_last_crawl, image_prod, is_new_product,
  } = elasticResult;

  const websiteState = websiteStates.hasOwnProperty(id_si) ? websiteStates[id_si].state : "OK";

  const { id_prod } = cleanElasticResult(elasticResult);

  const domain = getDomainFromUrl(url_prod);
  const isMatched = matchedProductIds.includes(id_prod);

  return (
    <div className='es-results' style={{padding :"0px 10px 0px 10px"}}> 
      <div>
        <a className="catalog-item" target="_blank">
          <img className="product-image" style = {{padding: '10px', float:'left'}} src= {image_nom_prod ? getImagePath(id_si, image_nom_prod) : image_prod} />
          <article style={{fontSize: '0.9rem'}}>
            <h2 className="product-title">{makeSpanLink(nom_prod, url_prod)}</h2>
            <Description
              className="product-card"
              sourceProductId={productId}
              domain={domain}
              websiteState={websiteState}
              httpCode={http_code}
              productId={id_prod}
              allReferences={[reference, ref_fournisseur, ref_fabriquant, ean]}
              dateLastCrawl={date_last_crawl ? new Date(date_last_crawl) : null}
              isMatched={isMatched}
              notifHandler={notifHandler}
              onMatchCallEnded={handleMatchCallEnded}
              product={elasticResult}
              isShowPrice={is_new_product}
              vatSite={vatSite}
              tauxPrice={tauxPrice}
              competitorsVat={competitorsVat}
            />
          </article>
        </a>
      </div>
    </div>
  )
};

export default generateResultItemCompetitor;
