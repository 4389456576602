/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import callApi from '../../helper/api-fetch';
import '../../styles/PriceLabel.css';

class PriceLabel extends Component {
  static makeWrenchJsx(text, title) {
    return (
      <span className="wrench">
        <span className="fas fa-wrench" title={title} />
        {` ${text}`}
      </span>
    );
  }

  static makePriceLabelJsx(mainLabel, subLabel) {
    return (
      <React.Fragment>
        <span className="label">{mainLabel}</span>
        {subLabel && (
          <div className="subLabel">
            {subLabel}
          </div>
        )}
      </React.Fragment>
    );
  }
  static makeLabelJsx(mainLabel, subLabel) {
      return (
        <React.Fragment>
          <span className="labelGreen">{mainLabel}</span>
            <div className="subLabel">
              {subLabel}
            </div>
        </React.Fragment>
      );
  }

  constructor(props) {
    super(props);

    this.missingStatusCodes = [404, 410, 418];
    this.okStatusCodes = [200];

    this.inaccessiblePageRegex = /^5\d{2}$/;
    this.blockedRegex = /^4(?!10)(?!04)(?!18)[0-9]{2}$/;

    const { httpCode, price, websiteState } = this.props;

    const emptyPrice = Number.isNaN(price) || Number(price) === 0;
    const shouldFetchFailureDate = emptyPrice
      && websiteState === 'OK'
      && (this.missingStatusCodes.includes(httpCode) || this.okStatusCodes.includes(httpCode));

    this.state = {
      emptyPrice,
      shouldFetchFailureDate,
      dataRetrieved: !shouldFetchFailureDate,
      failureDate: null,
    };
  }

  componentDidMount() {
    const { shouldFetchFailureDate } = this.state;

    if (!shouldFetchFailureDate) {
      return;
    }

    const { productId } = this.props;
    callApi({
      path: `/products/${productId}/failure_crawl`,
      onSuccess: (data) => {
        this.setState({
          failureDate: new Date(data.crawlDate),
          dataRetrieved: true,
        });
      },
      onError: () => {
        this.setState({
          failureDate: null,
          dataRetrieved: true,
        });
      },
    });
  }

  getLabel() {
    const { failureDate, emptyPrice } = this.state;
    const { httpCode, websiteState, dateLastCrawl, isShowPrice, isMatched , handtoggleMatching} = this.props;
    
    if(!isShowPrice && !isMatched) {
      return ;
    }

    if(!isShowPrice && isMatched && handtoggleMatching) {
      return this.constructor.makeLabelJsx('MACHING EFFECTUEE' , 'Le prix sera mis à jour dans 24h');
    }

    if (!emptyPrice) {
      const { price } = this.props;
      return price;
    }

    if (websiteState === 'REDIRECTED_NO_PRICE') {
      return this.constructor.makeWrenchJsx('intervention', 'intervention D');
    }

    if (websiteState === 'TO_RECONFIGURE') {
      return this.constructor.makeWrenchJsx('intervention', 'intervention C');
    }

    if (websiteState === 'BLOCKED') {
      return this.constructor.makeWrenchJsx('intervention', 'intervention B');
    }

    if (websiteState === 'BROKEN') {
      return this.constructor.makePriceLabelJsx('Site KO');
    }

    if (websiteState === 'MODIFIED_URLS') {
      return this.constructor.makeWrenchJsx('intervention', 'intervention A');
    }

    if (!httpCode || httpCode === 0 || String(httpCode).match(this.inaccessiblePageRegex)) {
      const dateLabel = dateLastCrawl ? `le ${dateLastCrawl.toISOString().slice(0, 10)}` : null;
      return this.constructor.makePriceLabelJsx('Page inaccessible', dateLabel);
    }

    if (String(httpCode).match(this.blockedRegex)) {
      return this.constructor.makeWrenchJsx('intervention', 'intervention B');
    }

    // eslint-disable-next-line max-len
    const millisecondsSinceLastCrawl = new Date().getTime() - (dateLastCrawl ? dateLastCrawl.getTime() : 0);
    const lessThanThreeDaysSinceLastCrawl = millisecondsSinceLastCrawl / (1000 * 60 * 60 * 24) < 3;

    if (this.okStatusCodes.includes(httpCode) || this.missingStatusCodes.includes(httpCode)) {
      let mainLabel = null;
      let dateLabel = null;

      if (lessThanThreeDaysSinceLastCrawl && failureDate) {
        mainLabel = this.okStatusCodes.includes(httpCode) ? 'Rupture de stock' : 'Page supprimée';
        dateLabel = failureDate ? `le ${failureDate.toISOString().slice(0, 10)} à ${failureDate.getHours()}:${failureDate.getMinutes()}` : null;
      } else {
        mainLabel = this.okStatusCodes.includes(httpCode) ? 'En rupture' : 'Page absente';
        dateLabel = dateLastCrawl ? `Dernier crawl: ${dateLastCrawl.toISOString().slice(0, 10)}` : null;
      }

      return this.constructor.makePriceLabelJsx(mainLabel, dateLabel);
    }

    // default
    const dateLabel = dateLastCrawl ? `le ${dateLastCrawl.toISOString().slice(0, 10)}` : null;
    return this.constructor.makePriceLabelJsx('Page inaccessible', dateLabel);
  }

  getClasseName(){
    const {isShowPrice} = this.props;
    const {emptyPrice} = this.state;
    return !isShowPrice ?  'messageLabel' : emptyPrice ? 'messageLabel' : 'priceLabel';
  }
 
  render() {
    const { dataRetrieved} = this.state;
    return (
      <div className={this.getClasseName()}>
        {dataRetrieved && this.getLabel()}
      </div>
    );
  }
}

PriceLabel.propTypes = {
  dateLastCrawl: PropTypes.instanceOf(Date),
  httpCode: PropTypes.number,
  websiteState: PropTypes.string,
  price: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  isShowPrice : PropTypes.bool.isRequired,
  isMatched : PropTypes.bool.isRequired
};

export default PriceLabel;
